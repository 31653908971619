import { getCurrentUser } from "helpers/Utils";
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  SET_USER_INFO,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_ERROR,
  PASSWORD_UPDATED_SUCCESSFULLY,
  RESET_PASSWORD_UPDATED,
  HIDE_PROFILE_LOADER,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_SUCCESS,
} from "../actions";

const INIT_STATE = {
  currentUser: getCurrentUser(),
  forgotUserMail: "",
  newPassword: "",
  resetPasswordCode: "",
  passwordUpdated: false,
  loading: false,
  error: "",
  userProfile: {},
  success: false,
  message: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case SIGNUP_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        passwordUpdated: false,
        loading: false,
        currentUser: action.payload,
        error: "",
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action?.payload?.message ?? "Something went wrong",
      };
    case PASSWORD_UPDATED_SUCCESSFULLY:
      return {
        ...state,
        passwordUpdated: true,
      };
    case SET_USER_INFO:
      return {
        ...state,
        passwordUpdated: false,
        userProfile: action.payload,
      };
    case SIGNUP_USER_SUCCESS:
      return {
        ...state,
        passwordUpdated: false,
        loading: false,
        error: "",
      };
    case SIGNUP_USER_ERROR:
      return {
        ...state,
        passwordUpdated: false,
        loading: false,
        error: action?.payload?.message ?? "Something went wrong",
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        passwordUpdated: false,
        success: false,
        loading: true,
        error: "",
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordUpdated: false,
        loading: false,
        forgotUserMail: action.payload,
        error: "",
        success: true,
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        passwordUpdated: false,
        forgotUserMail: "",
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, passwordUpdated: false, loading: true, error: "" };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordUpdated: false,
        newPassword: action.payload,
        resetPasswordCode: "",
        error: "",
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        passwordUpdated: false,
        newPassword: "",
        resetPasswordCode: "",
        error: action.payload.message,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        profileLoader: true,
      };
    case HIDE_PROFILE_LOADER:
      return {
        ...state,
        profileLoader: false,
        message: "User updated successfully!",
      };
    case LOGOUT_USER:
      return { ...state, passwordUpdated: false, currentUser: null, error: "" };
    case RESET_PASSWORD_UPDATED:
      return { ...state, passwordUpdated: false };
    default:
      return { ...state };
  }
};
