import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { appRoot, loginPage } from "constants/defaultValues";
import { setCurrentUser } from "helpers/Utils";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import {
  LOGIN_USER,
  GET_USER_INFO,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  SIGNUP_USER,
  UPDATE_USER_INFO,
  SET_PASSWORD,
  PASSWORD_UPDATED_SUCCESSFULLY,
  LOGIN_WITH_GMAIL,
  HIDE_PROFILE_LOADER,
} from "../actions";

import {
  loginUserSuccess,
  loginUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  signupUserSuccess,
  signupUserError,
  setUserProfile,
  updateUserInfoSuccess,
} from "./actions";

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLoginWithGmail() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_WITH_GMAIL, loginWithGmail);
}

export function* watchGetUserInfo() {
  yield takeEvery(GET_USER_INFO, getUserInformation);
}

export function* watchUpdateUserInfo() {
  yield takeEvery(UPDATE_USER_INFO, updateUserInformation);
}

export function* watchSetPassword() {
  yield takeEvery(SET_PASSWORD, setPassword);
}

export function* watchSignUpUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SIGNUP_USER, signupWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  let param = {
    password: password,
    email: email,
  };

  try {
    let response = await axios.post(servicePath + "/login", param);
    // console.log("loginWithEmailPasswordAsync", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const loginWithGmailAsync = async (param) => {
  try {
    let response = await axios.post(servicePath + "/externallogin", param);
    console.log("er", response.data);
    return response.data;
  } catch (error) {}
};

const getUserInformationAsync = async () => {
  try {
    let response = await axios.get(
      servicePath +
        "/users/" +
        JSON.parse(localStorage.getItem("oval_app_current_user")).user.id,
      {
        headers: {
          "x-access-token": JSON.parse(
            localStorage.getItem("oval_app_current_user")
          ).accessToken,
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const updateUserInformationAsync = async (payload) => {
  console.log(payload);
  try {
    let response = await axios.patch(
      servicePath +
        "/users/" +
        JSON.parse(localStorage.getItem("oval_app_current_user")).user.id,
      payload,
      {
        headers: {
          "x-access-token": JSON.parse(
            localStorage.getItem("oval_app_current_user")
          ).accessToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const setPasswordAsync = async (payload) => {
  console.log(payload);
  try {
    let response = await axios.post(servicePath + "/setPassword", payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const signupWithEmailPasswordAsync = async (email, password, username) => {
  let param = {
    password: password,
    email: email,
    username: username
  };

  try {
    let response = await axios.post(servicePath + "/register", param);
    console.log("er", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (!loginUser.message) {
      setCurrentUser(loginUser);
      yield put(loginUserSuccess(loginUser));
      history.push(appRoot);

    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

function* loginWithGmail({ payload }) {
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithGmailAsync, payload.user);

    if (!loginUser.message) {
      setCurrentUser(loginUser);
      yield put(loginUserSuccess(loginUser));

      history.replace(appRoot);
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

function* getUserInformation() {
  try {
    const user = yield call(getUserInformationAsync);

    if (!user.message) {
      console.log(user);
      yield put(setUserProfile(user));
      // history.push(appRoot);
    } else {
      yield put(loginUserError(user.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

function* updateUserInformation({ payload }) {
  try {
    console.log(payload);
    const user = yield call(updateUserInformationAsync, payload);

    if (user.message === "User updated successfully!") {
      yield put({ type: HIDE_PROFILE_LOADER });
    }
  } catch (error) {
    console.log(error);
    yield put(loginUserError(error));
  }
}

function* setPassword({ payload }) {
  try {
    console.log(payload);
    const user = yield call(setPasswordAsync, payload);

    if (!user.message) {
      console.log(user);
      yield put({ type: PASSWORD_UPDATED_SUCCESSFULLY });
    } else {
      yield put(loginUserError(user.message));
    }
  } catch (error) {
    console.log(error);
    yield put(loginUserError(error));
  }
}

function* signupWithEmailPassword({ payload }) {
  const { email, password, username } = payload.user;
  const { history } = payload;
  try {
    const response = yield call(signupWithEmailPasswordAsync, email, password, username);
    console.log(response.message);
    if (response.message === "User Created successfully!") {
      yield put(signupUserSuccess(response));
      console.log("here");
      history.push(loginPage);
    } else {
      console.log("hereasdfas");
      yield put(signupUserError(response.message));
    }
  } catch (error) {
    console.log("herasdfwe", error);
    yield put(signupUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
  // window.location.reload();
}

const logoutAsync = async (history) => {
  history.replace(loginPage);
  window.location.reload();
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  console.log("asdfa");
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  let param = {
    email: email,
  };

  try {
    let response = await axios.post(servicePath + "/forgotpassword", param);
    console.log(response);
    return response;
  } catch (error) {
    console.log("error", error);
    return error;
  }
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const response = yield call(forgotPasswordAsync, email);
    console.log(response);
    if (response.data.status) {
      yield put(forgotPasswordSuccess("success"));
    } else {
      yield put(forgotPasswordError(response.data.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await auth
    .confirmPasswordReset(resetPasswordCode, newPassword)
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess("success"));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLoginWithGmail),
    fork(watchSetPassword),
    fork(watchGetUserInfo),
    fork(watchUpdateUserInfo),
    fork(watchSignUpUser),
    fork(watchLogoutUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
