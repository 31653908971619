/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_WITH_GMAIL = "LOGIN_WITH_GMAIL";
export const GET_USER_INFO = "GET_USER_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const SIGNUP_USER = "SIGNUP_USER";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const HIDE_PROFILE_LOADER = "HIDE_PROFILE_LOADER";
export const SET_PASSWORD = "SET_PASSWORD";
export const RESET_PASSWORD_UPDATED = "RESET_PASSWORD_UPDATED";
export const PASSWORD_UPDATED_SUCCESSFULLY = "PASSWORD_UPDATED_SUCCESSFULLY";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNUP_USER_ERROR = "SIGNUP_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

/* Wigets Form */
export const WIGETS_FORM_SET = "WIGETS_FORM_SET";
export const WIGETS_FORM_REARRANGE = "WIGETS_FORM_REARRANGE";
export const SET_PROFILE_WIDGET = "SET_PROFILE_WIDGET";
export const WIGETS_FORM_GET = "WIGETS_FORM_GET";
export const WIGETS_FORM_UPDATE = "WIGETS_FORM_UPDATE";

export const SAVE_CARD = "SAVE_CARD";
export const UPDATE_CARD = "UPDATE_CARD";
export const SET_USERNAME_EXIST_ERROR = "SET_USERNAME_EXIST_ERROR";
export const GET_CARD = "GET_CARD";
export const GET_CARD_BY_USERNAME = "GET_CARD_BY_USERNAME";
export const SET_WIDGETS_FORM_DATA = "SET_WIDGETS_FORM_DATA";
export const SET_USERPROFILE_LINK = "SET_USERPROFILE_LINK";
export const SET_PROFILE_THEME = "SET_PROFILE_THEME";

/* Templates */
export const TEMPLATES_GET = "TEMPLATES_GET";
export const TEMPLATES_GET_SUCCESS = "TEMPLATES_GET_SUCCESS";
export const TEMPLATES_GET_ERROR = "TEMPLATES_GET_ERROR";

export const ICONS_GET = "ICONS_GET";
export const ICONS_GET_SUCCESS = "ICONS_GET_SUCCESS";
export const ICONS_GET_ERROR = "ICONS_GET_ERROR";

export * from "./auth/actions";
export * from "./templates/actions";
export * from "./widgetsForm/actions";
