import {
	TEMPLATES_GET,
	TEMPLATES_GET_SUCCESS,
	TEMPLATES_GET_ERROR,
	ICONS_GET,
	ICONS_GET_SUCCESS,
	ICONS_GET_ERROR
} from "../actions";

export const getTemplates = (templates, history) => ({
	type: TEMPLATES_GET,
	payload: { templates, history },
});

export const getTemplatesSuccess = (templates) => ({
	type: TEMPLATES_GET_SUCCESS,
	payload: templates,
});

export const getTemplatesError = (message) => ({
	type: TEMPLATES_GET_ERROR,
	payload: { message },
});


export const getIcons = (icons, history) => ({
	type: ICONS_GET,
	payload: { icons, history },
});

export const getIconsSuccess = (icons) => ({
	type: ICONS_GET_SUCCESS,
	payload: icons,
});

export const getIconsError = (message) => ({
	type: ICONS_GET_ERROR,
	payload: { message },
});

