import 'assets/css/vendor/bootstrap.min.css';

// custom styles
import "styles/scss/bootstrapoverride.scss";
import "styles/scss/custom.scss";

// Themes
import "styles/themes/BasicTheme.scss";
import "styles/themes/BasicDarkTheme.scss";
import "styles/themes/StrokeButton.scss";
import "styles/themes/DynamicBlue.scss";
import "styles/themes/CoolWhite.scss";
import "styles/themes/Funky.scss";
import "styles/themes/SunnyYellow.scss"; 
// import "styles/themes/DynamicBlue..scss";

import _ from 'lodash';
window._ = _;

const render = () => {
	require('./AppRenderer');
};

render();