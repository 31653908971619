import {
    TEMPLATES_GET,
    TEMPLATES_GET_SUCCESS,
    TEMPLATES_GET_ERROR,
    ICONS_GET,
    ICONS_GET_SUCCESS,
    ICONS_GET_ERROR
} from "../actions";

const INIT_STATE = {
    templates: [],
    icons: [],
    loading: false,
    error: "",
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TEMPLATES_GET:
            return { ...state, loading: true, error: "" };
        case TEMPLATES_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                templates: action.payload,
                error: "",
            };
        case TEMPLATES_GET_ERROR:
            return {
                ...state,
                loading: false,
                templates: [],
                error: action.payload.message,
            };
        case ICONS_GET:
            return { ...state, loading: true, error: "" };
        case ICONS_GET_SUCCESS:
            return {
                ...state,
                loading: false,
                icons: action.payload,
                error: "",
            };
        case ICONS_GET_ERROR:
            return {
                ...state,
                loading: false,
                icons: [],
                error: action.payload.message,
            };
        default:
            return { ...state };
    }
};
