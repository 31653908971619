/* eslint-disable no-param-reassign */
import {
    WIGETS_FORM_SET,
    WIGETS_FORM_UPDATE,
    WIGETS_FORM_REARRANGE,
    WIGETS_FORM_GET,
    SAVE_CARD,
    UPDATE_CARD,
    GET_CARD,
    SET_WIDGETS_FORM_DATA,
    SET_USERPROFILE_LINK,
    SET_PROFILE_WIDGET,
    SET_USERNAME_EXIST_ERROR,
    GET_CARD_BY_USERNAME,
    SET_PROFILE_THEME
} from '../actions';


export const addWidgetsFormData = (widgetsFormData) => {
    return {
        type: WIGETS_FORM_SET,
        payload: widgetsFormData,
    };
};

export const updateWidgetsFormData = (widgetsFormData, index) => {
    return {
        type: WIGETS_FORM_UPDATE,
        payload: { data: widgetsFormData, index },
    };
};

export const saveCard = (id, card) => ({
	type: SAVE_CARD,
	payload: { id, card },
});

export const updateCard = (id, card) => ({
	type: UPDATE_CARD,
	payload: { id, card },
});

export const getCard = (id) => ({
	type: GET_CARD,
	payload: { id },
});

export const getCardByUsername = (id) => ({
	type: GET_CARD_BY_USERNAME,
	payload: { id },
});


export const setWidgetFormsData = (card) => ({
	type: SET_WIDGETS_FORM_DATA,
	payload: { card },
});

export const setProfileLink = (card) => ({
	type: SET_USERPROFILE_LINK,
	payload: { card },
});

export const setProfileTheme = (card) => ({
	type: SET_PROFILE_THEME,
	payload: { card },
});

export const setUsernameExistError = (error) => ({
	type: SET_USERNAME_EXIST_ERROR,
	payload: error,
});


export const reArrangeWidgetsFormData = (widgetsFormData) => {
    return {
        type: WIGETS_FORM_REARRANGE,
        payload: widgetsFormData,
    };
};

export const showProfileWidget = (widgetsFormData) => {
    return {
        type: SET_PROFILE_WIDGET,
        payload: widgetsFormData,
    };
};

