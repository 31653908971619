import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import widgetSagas from './widgetsForm/saga';
import templatesSagas from './templates/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    widgetSagas(),
    templatesSagas(),
  ]);
}
