import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const configureStore = () => {
	const store = createStore(
		reducers,
		compose(applyMiddleware(...middlewares)),
	);

	sagaMiddleware.run(sagas);
	return store;
};
 
const store = configureStore()

export default store;
