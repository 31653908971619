import { defualtWidgetTheme, widgetThemeStorageKey } from "constants/defaultValues";

export const getCurrentTheme = () => {
    let currentColor = defualtWidgetTheme;
    try {
      
        if (localStorage.getItem(widgetThemeStorageKey)) {
            currentColor = localStorage.getItem(widgetThemeStorageKey);
        }
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : getCurrentColor -> error", error);
        currentColor = defualtWidgetTheme;
    }
    return currentColor;
};

export const setCurrentTheme = (color) => {
    try {
        
        localStorage.setItem(widgetThemeStorageKey, color);
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : setCurrentColor -> error", error);
    }
};


export const getCurrentUser = () => {
    let user = null;
    try {
        user = localStorage.getItem("oval_app_current_user") != null ? JSON.parse(localStorage.getItem("oval_app_current_user")) : null;
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
        user = null;
    }
    return user;
};

export const getAccessToken = () => {
    let user = null;
    try {
        user = localStorage.getItem("oval_app_current_user") != null ? JSON.parse(localStorage.getItem("oval_app_current_user")) : null;

        return user != null ? user.accessToken : null;
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js  : getCurrentUser -> error", error);
        user = null;

        return user;
    }
};

export const setCurrentUser = (user) => {
    try {
        if (user) {
            localStorage.setItem("oval_app_current_user", JSON.stringify(user));
        } else {
            localStorage.removeItem("oval_app_current_user");
        }
    } catch (error) {
        console.log(">>>>: src/helpers/Utils.js : setCurrentUser -> error", error);
    }
};
