import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { appRoot } from "constants/defaultValues";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { GET_CARD, GET_CARD_BY_USERNAME, SAVE_CARD, UPDATE_CARD } from "../actions";
import { setWidgetFormsData, setProfileLink, showProfileWidget, setUsernameExistError, setProfileTheme } from "./actions";
import { getCurrentTheme, setCurrentTheme } from 'helpers/Utils';

export function* watchSaveCard() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(SAVE_CARD, saveCard);
}

export function* watchUpdateCard() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(UPDATE_CARD, updateCard);
}

export function* watchGetCard() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(GET_CARD, getCard);
}

export function* watchGetCardByUsername() {
	// eslint-disable-next-line no-use-before-define
	yield takeEvery(GET_CARD_BY_USERNAME, getCardByUsername);
}

const saveCardAsync = async (id, card) => {
	let param = {
		id,
		jsonObject: card,
		templateId: getCurrentTheme()
	}

	try {
		let response = await axios.post(servicePath + '/saveuserprofile', param);
		return response.data;
	} catch (error) {

	}
};

const updateCardAsync = async (id, card) => {
	let param = {
		id,
		// path: card.username,
		...card
	}

	try {
		let response = await axios.put(servicePath + `/updateuserprofile/${id}`, param);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

const getCardAsync = async (id, card) => {
	let param = {
		id,
		card,
	}

	try {
		let response = await axios.post(servicePath + '/getuserprofile', param);
		console.log(response);
		return response.data.card;
	} catch (error) {

	}
};

const getCardByUsernameAsync = async (id, card) => {
	let param = {
		id,
		card,
	}

	try {
		let response = await axios.post(servicePath + '/getuserprofilebyusername', param);
		console.log(response);
		return response.data.card;
	} catch (error) {

	}
};

function* saveCard({ payload }) {
	const { id, card } = payload
	console.log(payload)
	try {
		const response = yield call(saveCardAsync, id, card);
		if (!response.message) {

		} else {
		}
	} catch (error) {
	}
}

function* updateCard({ payload }) {
	const { id, card } = payload
	// return
	try {
		const response = yield call(updateCardAsync, id, card);
		if (response.error) {
			console.log("response", response)
			yield put(setUsernameExistError(response));
		} else {
			yield put(setProfileLink(response.data));
			yield put(setUsernameExistError(false));
		}
	} catch (error) {
		console.log(error)
	}
}

function* getCard({ payload }) {
	const { id } = payload

	try {

		const card = yield call(getCardAsync, id);
 
		if (card) {
			yield put(setWidgetFormsData(JSON.parse(card.jsonObject)));
			yield put(setProfileLink(card.path));
			yield put(setProfileTheme(card.ovalThemeSelectedColor));
			yield put(showProfileWidget(false));
		} else {
			yield put(showProfileWidget(true));
		}
	} catch (error) {
		console.log(error)
		// yield put(loginUserError(error));
	}
}

function* getCardByUsername({ payload }) {
	const { id } = payload
	try {
		const card = yield call(getCardByUsernameAsync, id);
		if (card) {
			yield put(setWidgetFormsData(JSON.parse(card.jsonObject)));
			yield put(setProfileLink(card.path));
			yield put(setProfileTheme(card.ovalThemeSelectedColor));
			yield put(showProfileWidget(false));
		} else {
			yield put(showProfileWidget(true));
		}
	} catch (error) {
		console.log(error)
		// yield put(loginUserError(error));
	}
}

export default function* rootSaga() {
	yield all([fork(watchSaveCard), fork(watchUpdateCard), fork(watchGetCard), fork(watchGetCardByUsername)]);
}
