import {
    WIGETS_FORM_SET,
    WIGETS_FORM_REARRANGE,
    WIGETS_FORM_GET,
    SET_WIDGETS_FORM_DATA,
    WIGETS_FORM_UPDATE,
    SET_USERPROFILE_LINK,
    SET_PROFILE_WIDGET,
    SET_USERNAME_EXIST_ERROR,
    SET_PROFILE_THEME
} from '../actions';

const INIT_STATE = {
    widgetsFormData: [],
    userProfileLink: '',
    userProfileTheme: '',
    setProfileWidget: false,
    usernameExistError: true
};

export default (state = INIT_STATE, action) => {
    // console.log(action)
    switch (action.type) {
        case WIGETS_FORM_SET:
            return {
                ...state,
                widgetsFormData: [...state.widgetsFormData, action.payload]
            };
        case WIGETS_FORM_UPDATE:
            console.log(state.widgetsFormData)
            const array = [...state.widgetsFormData];
            array[action.payload.index] = action.payload.data;

            console.log(array);

            return {
                ...state,
                widgetsFormData: array
            };
        case WIGETS_FORM_REARRANGE:
            return {
                ...state,
                widgetsFormData: action.payload
            };
        case SET_WIDGETS_FORM_DATA:
            return {
                ...state,
                widgetsFormData: action.payload.card
            };
        case SET_USERPROFILE_LINK:
            return {
                ...state,
                userProfileLink: action.payload.card
            };
        case SET_PROFILE_THEME:
            return {
                ...state,
                userProfileTheme: action.payload.card
            };
        case SET_USERNAME_EXIST_ERROR:
            return {
                ...state,
                usernameExistError: action.payload
            };
        case SET_PROFILE_WIDGET:
            return {
                ...state,
                setProfileWidget: action.payload
            };
        default:
            return { ...state };
    }
};
