import { combineReducers } from 'redux';

import authUser from './auth/reducer';
import widgetsForm from './widgetsForm/reducer';
import templatesData from './templates/reduces';

const reducers = combineReducers({
    authUser,
    widgetsForm,
    templatesData
});

export default reducers;
